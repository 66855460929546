<template>

  <span v-if="showTooltip" class="tooltip tooltip-accent" :data-tip="actualDate(dateNumber)">
    {{relativeDate(dateNumber)}} ago
  </span>

  <span v-else>
    {{ relativeDate(dateNumber) }} ago
  </span>

</template>

<style scoped>

.tooltip {
  @apply underline-offset-4 underline;
  text-decoration-color: oklch(var(--b3));
  text-decoration-style:dotted;
}

</style>

<script setup>
import {formatDistance} from 'date-fns'

const ticker = useTicker()

defineProps({
  dateNumber: {
    type: Number
  },
  showTooltip: {
    type: Boolean,
    default: true
  }
});

function actualDate(date) {
  return new Date(date)
}

function relativeDate(date) {

  // handle if we get a non date
  if(isNaN(date)) {
    console.log("Date is NaN")
    return "?"
  }
  
  return formatDistance(new Date(date), ticker.value)
}

</script>